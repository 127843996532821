$breakpointXS: 'only screen and (max-width: 767px)'; // mobile layout (width: 320px - 767px)
$breakpointS: 'only screen and (max-width: 991px)';  // tablet layout
$breakpointM: 'only screen and (max-width: 1200px)'; // small desktop
$breakpointL: 'only screen and (min-width: 1201px)'; // large desktop

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 2rem;

  @media #{$breakpointXS} {
    height: 80vmin;
  }
}

/*@media (prefers-reduced-motion: no-preference) {*/
  /*.App-logo {*/
    /*animation: App-logo-spin infinite 20s linear;*/
  /*}*/
/*}*/

.App-header {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Social-url
{
  padding: 1rem;
  color: #ffffff;
  border-radius: 1rem;
  text-decoration: none;
  transition: 0.3s;

  $color_fb: #3b5998;

  $color_ig_1: #fa7e1e;
  $color_ig_2: #d62976;

  $color_ig: linear-gradient(25deg, $color_ig_1 25%, $color_ig_2 75%);
  $color_ig_hover: linear-gradient(25deg, darken($color_ig_1, 3%) 25%, darken($color_ig_2, 3%) 75%);
  $color_ig_active: linear-gradient(25deg, darken($color_ig_1, 10%) 25%, darken($color_ig_2, 10%) 75%);

  &.fb {
    background: $color_fb;

    &:hover {
      background: darken($color_fb, 3%);
    }

    &:active {
      background: darken($color_fb, 10%);
    }
  }

  &.ig {
    background: $color_ig;

    &:hover {
      background: $color_ig_hover;
    }

    &:active {
      background: $color_ig_active;
    }
  }
}

.Social-url + .Social-url {
  margin-left: 1rem;
}

/*@keyframes App-logo-spin {*/
  /*from {*/
    /*transform: rotate(0deg);*/
  /*}*/
  /*to {*/
    /*transform: rotate(360deg);*/
  /*}*/
/*}*/
